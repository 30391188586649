import React, { FC } from "react";
import ReactMarkdown from "react-markdown";
import encodeurl from "encodeurl";

import Layout from "../components/layout";
import SEO from "../components/seo";

const subjectApp = encodeurl(`I need to get an app built`);

const textApp = encodeurl(`Dear react-native.dev team,

I have an idea for an app and need help to plan it, design it, build it and
release it.
The app is about (fill in short summary).
The development should start (fill in start date) and end (fill in date).
The budget is (fill in budget).

I am looking forward hearing from you!

Best Regards,`);

const subjectContract = encodeurl(
  `Looking to hire a React Native developer (contract)`
);

const textContract = encodeurl(`Dear react-native.dev team,

I have a project for a freelance React Native developer.
Description:
(fill in link to description for the project/position)
Experience level shold be (fill in level, e.g. Senior).
The developer should start from (fill in date).
Daily rate should not exceed (fill in maximum daily rate).

I am looking forward hearing from you!

Best Regards,`);

const subjectPerm = encodeurl(
  `Looking to hire a React Native developer (permanent)`
);

const textPerm = encodeurl(`Dear react-native.dev team,

I am looking to hire a React Native developer for a permanent position.
Description:
(fill in link to description for the project/position)
Experience level shold be (fill in level, e.g. Senior)
The developer should start from (fill in date).
Salary is between (fill in salary range).

I am looking forward hearing from you!

Best Regards,`);

const subjectTeam = encodeurl(
  `I need an external React Native development team`
);

const textTeam = encodeurl(`Dear react-native.dev team,

We are planning a mobile app project but do not have developers with the
technical expertise. Hence we need to outsource the development process.
Description:
(fill in link to description for the project/position)
The development should start (fill in start date) and end (fill in date).
The budget is (fill in budget).

I am looking forward hearing from you!

Best Regards,`);

const subjectTalk = encodeurl(`Let's talk!`);

const input = `
👋 Hi! How may we help you?

📱 Need an app? [project@react-native.dev](mailto:project@react-native.dev?subject=${subjectApp}&body=${textApp})

🧑‍💻 Got contract work? [freelance@react-native.dev](mailto:freelance@react-native.dev?subject=${subjectContract}&body=${textContract})

🤝 Are you hiring? [permanent@react-native.dev](mailto:permanent@react-native.dev?subject=${subjectPerm}&body=${textPerm})

🙌 Need a whole team? [team@react-native.dev](mailto:team@react-native.dev?subject=${subjectTeam}&body=${textTeam})

⚛️ In case you are looking for the docs, they are [here](https://reactnative.dev/docs/getting-started).

💬 Just want to talk? [hello@react-native.dev](mailto:hello@react-native.dev?subject=${subjectTalk})
`;

const IndexPage: FC = () => (
  <Layout>
    <SEO title="react-native.dev" />
    <div
      style={{
        padding: `0 1.0875rem`,
      }}
    >
      <ReactMarkdown source={input} />
    </div>
  </Layout>
);

export default IndexPage;
